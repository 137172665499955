import React from 'react';
import styles from './CustomerStoryHeader.module.scss';

interface CustomerStoryHeaderProps {
  coverUrl: string;
  title: string;
}

const CustomerStoryHeader: React.SFC<CustomerStoryHeaderProps> = ({
  coverUrl,
  title,
}) => (
  <div
    className={styles.container}
    style={{ backgroundImage: `url(${coverUrl})` }}
  >
    <div className={styles.overlay} />
    <div className={styles.contentOuter}>
      <div className={styles.contentInner}>
        <h3>{title}</h3>
      </div>
    </div>
  </div>
);

export default CustomerStoryHeader;
