import { graphql } from 'gatsby';
import React from 'react';
import CustomerStoryGrid from '../components/customer-stories/CustomerStoryGrid';
import CustomerStoryContent from '../components/customer-story/CustomerStoryContent';
import CustomerStoryHeader from '../components/customer-story/CustomerStoryHeader';
import CustomerStorySidebar from '../components/customer-story/CustomerStorySidebar';
import CustomerStorySummary from '../components/customer-story/CustomerStorySummary';
import MarketingLayout from '../components/layout/MarketingLayout';
import Button from '../components/ui/Button';
import Spacing from '../components/ui/Spacing';
import {
  CompaniesYamlConnection,
  MarkdownRemark,
  MarkdownRemarkConnection,
} from '../graphql-types';
import { Company } from '../types';
import fullStories, { fullStory } from '../utils/fullStories';
import styles from './CustomerStoryTemplate.module.scss';

interface CustomerStoryTemplateProps {
  data: {
    companies: CompaniesYamlConnection;
    stories: MarkdownRemarkConnection;
    story: MarkdownRemark;
  };
}

const OTHER_STORY_COUNT = 3;

const CustomerStoryTemplate: React.SFC<CustomerStoryTemplateProps> = ({
  data: { companies, stories, story },
}) => {
  // TODO: Fix this the next time the file is edited.
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const companySlug = story.frontmatter!.company;
  const company = companies.edges.find(
    (edge) => edge.node.fields!.slug === companySlug,
  )!.node;
  const coverUrl = story.frontmatter!.articleCover!.publicURL!;
  const title = story.frontmatter!.title!;
  const { htmlAst } = story;
  const companyName = company.name!;
  /* eslint-enabe @typescript-eslint/no-non-null-assertion */

  const storyIndex = stories.edges.findIndex(
    (edge) => edge.node.id === story.id,
  );
  const storiesWithCompanies = fullStories({ companies, stories });
  const otherStories = storiesWithCompanies
    .concat(storiesWithCompanies)
    .slice(storyIndex + 1, storyIndex + 1 + OTHER_STORY_COUNT);
  const storyWithCompany = fullStory(story, companies);

  return (
    <MarketingLayout
      title={`${companyName} Customer Story`}
      description={title}
      image={story.frontmatter!.indexCover!.publicURL!}
      noFooterPadding
    >
      <CustomerStoryHeader coverUrl={coverUrl} title={title} />
      <div className={styles.main}>
        <div className={styles.content}>
          <CustomerStorySummary story={storyWithCompany} />
          <CustomerStoryContent story={storyWithCompany} htmlAst={htmlAst} />
        </div>
        <aside className={styles.sidebar}>
          <CustomerStorySidebar company={company as Company} />
        </aside>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          <h4>More stories from Slab customers</h4>
          <CustomerStoryGrid stories={otherStories} />
          <Spacing top={8}>
            <Button to="/customer-stories/">View all stories</Button>
          </Spacing>
        </div>
      </div>
    </MarketingLayout>
  );
};

export default CustomerStoryTemplate;

export const query = graphql`
  query ($slug: String!) {
    ...AllCustomerStoriesQuery

    companies: allCompaniesYaml {
      edges {
        node {
          name
          industry
          url
          location
          founded
          logo {
            publicURL
          }
          fields {
            slug
          }
        }
      }
    }

    story: markdownRemark(
      fields: { slug: { eq: $slug }, type: { eq: "customer-stories" } }
    ) {
      id
      htmlAst
      fields {
        slug
        problem
        solution
        keyPoints
      }
      frontmatter {
        company
        title
        articleCover {
          publicURL
        }
        indexCover {
          publicURL
        }
        citations {
          id
          name
          title
          link
          quoteColor
          picture {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
