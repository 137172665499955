import React from 'react';
import RehypeReact from 'rehype-react';
import { CustomerStory } from '../../types';
import Quote from '../ui/Quote';
import styles from './CustomerStoryContent.module.scss';

interface CustomerStoryContentProps {
  htmlAst: object;
  story: CustomerStory;
}

const getRenderer = (otherProps: object) =>
  new RehypeReact({
    createElement: (
      component: React.ElementType,
      props: object,
      children: React.ElementType,
    ) =>
      React.createElement(
        component,
        typeof component === 'string' ? props : { ...otherProps, ...props },
        children,
      ),
    components: {
      'slab-quote': Quote,
    },
  }).Compiler;

const CustomerStoryContent: React.SFC<CustomerStoryContentProps> = ({
  htmlAst,
  story,
}) => {
  const { citations } = story;
  return (
    <div className={`${styles.container} post-typography`}>
      {getRenderer({ citations })(htmlAst)}
    </div>
  );
};

export default CustomerStoryContent;
