import React from 'react';
import { Company } from '../../types';
import RegisterForm from '../account/RegisterForm';
import styles from './CustomerStorySidebar.module.scss';

interface CustomerStoryProps {
  company: Company;
}

const CustomerStorySidebar: React.SFC<CustomerStoryProps> = ({
  company: { logo, name, url, industry, location, founded },
}) => (
  <>
    <div className={styles.card}>
      {logo?.publicURL && (
        <div className={styles.logo}>
          <a href={url} target="_blank" rel="noreferrer noopener">
            <img src={logo.publicURL} alt={name} />
          </a>
        </div>
      )}
      <dl className={styles.summaryList}>
        <dt>Industry</dt>
        <dd>{industry}</dd>
        <dt>Headquarters</dt>
        <dd>{location}</dd>
        <dt>Founded</dt>
        <dd>{founded}</dd>
      </dl>
    </div>
    <div className={`${styles.card} ${styles.registerCard}`}>
      <div className={styles.registerText}>Get started today</div>
      <div className={styles.form}>
        <RegisterForm source="customer-story" stack />
      </div>
    </div>
  </>
);

export default CustomerStorySidebar;
