import React from 'react';
import { CustomerStory } from '../../types';
import styles from './CustomerStorySummary.module.scss';

interface CustomerStorySummaryProps {
  story: CustomerStory;
}

const CustomerStorySummary: React.SFC<CustomerStorySummaryProps> = ({
  story: { problem, solution, keyPoints },
}) =>
  problem && solution ? (
    <div className={styles.container}>
      <h2>At a glance</h2>
      <h3>Problem</h3>
      <div
        className="post-typography"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: problem }}
      />
      <h3>Solution</h3>
      <div
        className="post-typography"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: solution }}
      />
      {keyPoints && (
        <>
          <h3>Key Points</h3>
          <div
            className="post-typography"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: keyPoints }}
          />
        </>
      )}
    </div>
  ) : null;

export default CustomerStorySummary;
